$app-dashboard-top-nav-height: rem-calc(55);

.app-dashboard-top-nav-bar  {
  height: $app-dashboard-top-nav-height;
  width: 100%;
  flex: 0 0 $app-dashboard-top-nav-height;
}

#mainMenuLimited {
    width: 100%;
    z-index: 10;
    
}

.test {
    top: 0;
}

#mainMenuRight {
    font-family: $specialFont;

    @media screen and (min-width: 640px) {
        float: right;
    }
}

#mainMenuRight a {
    color: white;
}

#mainMenuLeft {
    float: left;

    @media screen and (max-width: 640px) {
        display: none;
    }
}


#mainMenuLeft a {
    padding: 0;
}

#mainMenuLogo {
    max-width: 3.5rem;
}

//arrow of top bar
.top-bar .is-dropdown-submenu-parent > a::after {
  border-color: white rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)!important; 
 }
.top-bar .is-dropdown-submenu-parent > a.black::after {
  border-color: black rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)!important; 
 }

.menuBackgroundToColor {
    background-color: $topbar-background !important;
    background-image:none !important;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
.menuBackgroundToWhite {
    background-color: white !important;
    background-image:none !important;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.menuColorToColor {
    color: white !important;
    -webkit-transition: color 0.1s linear;
    -moz-transition: color 0.1s linear;
    -o-transition: color 0.1s linear;
    -ms-transition: color 0.1s linear;
    transition: color 0.1s linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;

    border-color: white rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)!important;
    -webkit-transition: border-color 0.1s linear;
    -moz-transition: border-color 0.1s linear;
    -o-transition: border-color 0.1s linear;
    -ms-transition: border-color 0.1s linear;
    transition: border-color 0.1s linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
.menuColorToWhite {
    color: black !important;
    -webkit-transition: color 0.1s linear;
    -moz-transition: color 0.1s linear;
    -o-transition: color 0.1s linear;
    -ms-transition: color 0.1s linear;
    transition: color 0.1s linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;

    border-color: black rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)!important;
    -webkit-transition: border-color 0.1s linear;
    -moz-transition: border-color 0.1s linear;
    -o-transition: border-color 0.1s linear;
    -ms-transition: border-color 0.1s linear;
    transition: border-color 0.1s linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

//overwrite default from foundation
.is-dropdown-submenu {
    min-width: unset;
}

@media screen and (max-width: 640px) {
    .menu ul {
        margin-left: 1rem;
    }
}

.top-bar {
    //bug in chromium: with flex several paddings are overlaped
    display: block;
    z-index: 99999;
}

.title-bar {
    display: block;
}