// generics
.noPadding {
    padding: 0!important;
}

.noLinkColor {
    color: inherit;
}

.gone {
    display: none;
}

.transparent {
    filter: alpha(opacity=100);
    opacity: 0;
}

.overflow {
    position: absolute;
}

.lightShadow {
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.8);
    z-index: 10;
}

.vertCent {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.horiCent {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.rightAlign {
    text-align: right;
}

@media screen and (max-width: 640px) {
    .goneOnSmall {
        display: none;
    }
}

@media screen and (min-width: 641px) and (max-width: 1023px){
    .goneOnMedium {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .goneOnLarge {
        display: none;
    }
}

// Side layout
#contentContainer {
    overflow-y: auto;
    position: relative;
}

#sideImg {
    width: 100%;
    position: relative;
    z-index: 1;
}

#sideImgLogo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    max-height: 100%;
    -webkit-transition: transform 1.5s, opacity 0.3s ease-in-out;
    -moz-transition: transform 1.5s, opacity 0.3s ease-in-out;
    -ms-transition: transform 1.5s, opacity 0.3s ease-in-out;
    -o-transition: transform 1.5s, opacity 0.3s ease-in-out;
    transition: transform 1.5s, opacity 0.3s ease-in-out;
}

#sideImgGlow:hover {
    transform: scale(1.1);
}

#sideImgGlow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    max-height: 100%;
    -webkit-transition: transform 1.5s, opacity 0.3s ease-in-out;
    -moz-transition: transform 1.5s, opacity 0.3s ease-in-out;
    -ms-transition: transform 1.5s, opacity 0.3s ease-in-out;
    -o-transition: transform 1.5s, opacity 0.3s ease-in-out;
    transition: transform 1.5s, opacity 0.3s ease-in-out;
}

#sideImgLogo:hover {
    transform: scale(1.1);
}

#innerContentContainer {
    position: relative;
    z-index: 3;
}

.emphasisContainer {
    margin-top: 1rem;
    background-color: $light-gray;
}

.hidden {
    display: none;
}

// side content
#sideHeadline {
    font-size: 400%;
    color: $lightblue;
    font-family: $specialFont;
    padding: 1rem;
}

@media screen and (max-width: 640px) {
    #sideHeadline {
        font-size: 10vw;
    }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
    #sideHeadline {
        font-size: 6vw;
    }
}

.subHeadline {
    font-size: 300%;
    color: $lightblue;
    font-family: $specialFont;
}

.subsubHeadline {
    font-size: 260%;
    color: $lightblue;
    font-family: $specialFont;
}

@media screen and (max-width: 640px) {
    .subHeadline {
        font-size: 7vw;
    }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
    .subHeadline {
        font-size: 4vw;
    }
}

.sideInfoText {
    font-size: 130%;
}

@media screen and (max-width: 640px) {
    .sideInfoText {
        font-size: 100%;
    }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
    .sideInfoText {
        font-size: 100%;
    }
}

#calendar {
    margin-bottom: 1rem;

    table {
        // foundation sets a margin to tables, which breakes fullContainer
        margin-bottom: 0 !important;
    }

    tbody {
        // foundation sets a background color to tbody, which breakes fullCalendar
        background-color: transparent !important;
    }
}

.highlightBox {
    background-color: $light-gray;
    margin: 0.5rem;
}

.highlightBoxHeader {
    background-color: $mainblue;
    height: 0.5rem;
}

.highlightBoxInner {
    padding: 0.7rem;
}

.nextElement {
    padding-top: 3rem;
}