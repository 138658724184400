.kapelleDescriptionText {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.kapelleRegisterNames {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 140%;
}

.kapelleDescriptionTextSideImage {
    padding: 2rem;
}