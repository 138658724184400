.bilderDescriptionText {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.gallery {
    padding-top: 2rem;
    padding-bottom: 4rem;
}

.galleryImageClicked {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    margin: 0!important;
    overflow: auto;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1001;
}

#pictureControlLeft {
    position: fixed;
    top: 50%;
    bottom: 50%;
    left: 5%;
    z-index: 1002;
}

#pictureControlRight {
    position: fixed;
    top: 50%;
    bottom: 50%;
    right: 5%;
    z-index: 1003;
}