.newsCardTextfield {
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.75);
    color: $lightblue;
    font-family: $specialFont;
}

.newsCardHeadline {
    -webkit-transition: height .2s linear;   
    -moz-transition: height .2s linear;   
    -ms-transition: height .2s linear;   
    -o-transition: height .2s linear;   
    transition: height .2s linear;  

    background: $mainblue;
    height: 0.7rem;
}

.newsCardName {
    font-size: 2.8vw;
    line-height: 1;
    margin: 0.2rem 0.5rem 0.2rem 0.5rem;
}

.newsCardDes {
    font-size: 1.6vw;
    line-height: 1;
    margin: 0 0.5rem 0.6rem 0.5rem;
}

@media screen and (max-width: 640px) {
    .newsCardName {
        font-size: 8vw;
    }
    .newsCardDes {
        font-size: 5vw;
    }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
    .newsCardName {
        font-size: 5vw;
    }
    .newsCardDes {
        font-size: 3vw;
    }
}

.newsCardCenter {
    text-align: center;
    align-self: center;
}

.newsCardRight {
    text-align: right;
    align-self: flex-end;
}

.newsCardLeft {
    text-align: left;
    align-self: flex-start;
}

.newsCardTextfield:hover {
    color: $lightblue;
    .newsCardHeadline {
        height: 1.1rem;
    }
}

@media screen and (max-width: 640px) {
    #sponsorsDes {
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        text-align: center;
    }
}

@media screen and (min-width: 641px) {
    #sponsorsDes {
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        text-align: center;
    }
}