.sponsorsBox {
    background-color: $light-gray;
    margin: 0.5rem;
}

.sponsorsBoxHeader {
    background-color: $mainblue;
    height: 0.5rem;
}

.sponsorsBoxInner {
    padding: 0.7rem;
}

.sponsorsImg {
}
