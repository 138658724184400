.jugendKonzeptSubHeadline {
    font-size: 125%;
    color: $lightblue;
    font-family: $specialFont;
}

.jugendKonzeptSubHeadlineNumber {
    font-size: 200%;
    color: $lightblue;
    font-family: $specialFont;
}

@media screen and (min-width: 1024px) {
    .jugendKonzeptStep {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.instrumentCardName p {
    font-size: 150%;
    color: $lightblue;
    font-family: $specialFont;
    text-align: center;
}

.instrumentInfoText {
    margin: 0;
    padding: 0;
    max-height: 1px;
    -webkit-transition: max-height .5s ease-out;
    -moz-transition: max-height .5s ease-out;
    -ms-transition: max-height .5s ease-out;
    -o-transition: max-height .5s ease-out;
    transition: max-height .5s linear;
    overflow: hidden;
}

.instrumentInfoTextFull {
    max-height: 150px;
}

.instrumentCardImg {
    transition: background-color .5s linear;
    background: $transparent;
}

.instrumentCardImgBlue {
    background: $lightblueOp;
}