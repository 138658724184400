.quote {
    text-align: center;
    font-size: 250%;
    font-family: $specialFont;
    color: $lightblue;
}

#beam {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

@media screen and (max-width: 640px) {
    .vorstandDescription {
        text-align: center;
        font-family: $specialFont;
        font-size: 150%;
        padding-left: 0.6rem;
    }

    .vorstandName {
        display: block;
        text-align: center;
        padding-left: 1rem;
        font-size: 130%;
    }
}

@media screen and (min-width: 641px) and (max-width: 1023px) {
    .vorstandDescription {
        font-family: $specialFont;
        font-size: 150%;
        padding-left: 0.6rem;
    }

    .vorstandName {
        padding-left: 1rem;
        font-size: 130%;
    }
}

@media screen and (min-width: 1024px) {
    .vorstandDescription {
        font-family: $specialFont;
        font-size: 150%;
        padding-left: 0.5rem;
    }

    .vorstandName {
        padding-left: 0.3rem;
        font-size: 130%;
        padding-left: 1rem;
    }
}

.musikerheimMap {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1023px) {
    .musikerheimMap {
        height: 25rem;
    }
}

.youtube {
    width: 100%;
    height: 40rem;
    frameborder: 0;
    border: 0;
}