.kontaktDescription {
    font-family: $specialFont;
    color: $lightblue;
    font-size: 150%;
    padding-left: 0.5rem;
}

.kontaktName {
    padding-left: 0.5rem;
    font-size: 130%;
}

.kontaktMail {
    padding-top: 2rem;
    padding-left: 0.5rem;
    font-size: 90%;
}

@media screen and (max-width: 1024px) {
    .kontaktDescription {
        font-family: $specialFont;
        font-size: 150%;
        padding-left: 0.5rem;
    }

    .kontaktName {
        padding-left: 0.3rem;
        font-size: 130%;
        padding-left: 1rem;
    }
}

.kontaktMap {
    width: 100%;
    height: 40rem;
}