footer {
    margin-top: 1.5rem;
    padding: 1rem 0 1rem 0;
    background-color: $mainblue;

    p {
        margin-bottom: 0;
        color: lightgray;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }

    span {
        margin-bottom: 0;
        color: lightgray;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }
}

#footerLeft {
    text-align: left;
    height: 100%;

    @media screen and (min-width: 640px) {
        margin-left: 1rem;
    }

    p {
        display: flex;
        height: 100%;
        align-items: center;
    }
}

#footerRight {
    @media screen and (min-width: 640px) {
        text-align: right;
    }

    img {
        margin: 0 0.4rem 0 0.4rem;
    }
}